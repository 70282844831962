import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { ArrowLeftSvg } from '../../../assets/icons';
import ArrowRightSvg from '../../../assets/icons/ArrowRightSvg';
import CuriBookmarksSvg from '../../../assets/icons/CuriBookmarks';
import theme from '../../../assets/theme';
import { GeneratorMinimumDTO } from '../../../services/backendService/types';
import { LicenseCoarseLevels } from '../../../utils/tenantPlanUtils';
import { Button } from '../../_atoms/Button';
import { HorisontalGeneratorCard } from '../../_molecules/HorisontalGeneratorCard';
import LiveEmptyState from '../../_molecules/LiveEmptyState';

const removeSymbolsFromCollectionName = (collectionName: string) => {
  //url-encode the url when we redirect incase there are special symbols
  return encodeURIComponent(collectionName);
};

export const CollectionSlidingView = ({
  collectionId,
  title,
  generators,
}: {
  collectionId: string;
  title: string;
  generators: GeneratorMinimumDTO[];
}) => {
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const listRef = useRef<HTMLUListElement>(null);
  const handleRightArrowClicked = () => {
    const itemsToScroll = 2;
    if (listRef.current) {
      const nodes = listRef.current.childNodes;
      const allNodes = Array.from(nodes);

      // We assume all nodes have the same width
      //@ts-ignore
      const nodeWidth = allNodes?.[0]?.clientWidth;
      const gap = 10;
      const nextCheckpoint =
        listRef.current.scrollLeft + (nodeWidth + gap) * itemsToScroll;

      listRef.current.scrollTo({
        left:
          nextCheckpoint > listRef.current.scrollWidth
            ? listRef.current.scrollWidth
            : nextCheckpoint,
        behavior: 'smooth',
      });
    }
  };
  const handleLeftArrowClicked = () => {
    const itemsToScroll = 2;

    if (listRef.current) {
      const nodes = listRef.current.childNodes;
      const allNodes = Array.from(nodes);

      // We assume all nodes have the same width
      //@ts-ignore
      const nodeWidth = allNodes?.[0]?.clientWidth;
      const gap = 10;
      const nextCheckpoint =
        listRef.current.scrollLeft - (nodeWidth + gap) * itemsToScroll;

      listRef.current.scrollTo({
        left: nextCheckpoint < 0 ? 0 : nextCheckpoint,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    // We have a scroll listener to always know the latest scroll position
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const scrollHandler: EventListener = (ev: any) => {
      const scrollLeft = ev.target.scrollLeft;

      if (scrollLeft === 0) {
        setShowLeftArrow(false);
      } else {
        setShowLeftArrow(true);
      }

      if (scrollLeft + ev.target.clientWidth >= ev.target.scrollWidth - 15) {
        //10 as a buffer
        setShowRightArrow(false);
      } else {
        setShowRightArrow(true);
      }
    };
    const ref = listRef.current;
    ref?.addEventListener('scroll', scrollHandler);

    if (ref && ref.clientWidth < ref.scrollWidth) {
      // Upon mounting we check if we need to show the right arrow if there is a scroll
      setShowRightArrow(true);
    }

    return () => {
      if (ref) {
        ref.removeEventListener('scroll', scrollHandler);
      }
    };
  }, [generators]);

  if (generators?.length === 0) {
    return (
      <DiscoveryRowContainer key={collectionId}>
        <CollectionTitleRow>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Title $fontSize={16}>{title}</Title>
          </div>
        </CollectionTitleRow>
        <EmptyStateContainer $isGeneratorRow={true}>
          <LiveEmptyState
            maxWidth={500}
            icon={<CuriBookmarksSvg />}
            title={`No customizable lessons in this collection yet.`}
          />
        </EmptyStateContainer>
      </DiscoveryRowContainer>
    );
  }

  return (
    <DiscoveryRowContainer key={collectionId}>
      <CollectionTitleRow>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Title $fontSize={16}>{title}</Title>
        </div>
        <Link
          href={`/collections/${removeSymbolsFromCollectionName(title)}/${collectionId}`}
        >
          <a style={{ color: theme.colors.white }}>See more</a>
        </Link>
      </CollectionTitleRow>
      <ScrollMainContainer>
        {showLeftArrow && (
          <GeneratorsLeftArrowContainer onClick={handleLeftArrowClicked}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                height: '100%',
                paddingBottom: 20,
              }}
            >
              <StyledArrowButton
                styles={{
                  background: theme.colors.white,
                  border: 'none',
                  height: 35,
                  width: 35,
                  borderRadius: '50%',
                  color: theme.colors.black,
                }}
                type="ghost"
                icon={<ArrowLeftSvg />}
                notext={true}
                onClick={() => {
                  handleLeftArrowClicked();
                }}
              />
            </div>
          </GeneratorsLeftArrowContainer>
        )}
        <HorisontalScrollContainer ref={listRef}>
          {generators?.map(generator => {
            return (
              <div
                key={generator.id}
                style={{ height: '100%', width: 'fit-content', position: 'relative' }}
              >
                <Link
                  href={`/generators/${generator.title}/g/${generator.id}?collectionId=${collectionId}`}
                >
                  <a
                    style={{ height: '100%', width: 'fit-content', position: 'relative' }}
                  >
                    <HorisontalGeneratorCard
                      coverImageId={generator.coverImageId}
                      firstPublished={0}
                      title={generator.title}
                      status={'published'}
                      isPremiumGenerator={
                        generator.generatorPlan === LicenseCoarseLevels.premium
                      }
                      generatorPlan={generator.generatorPlan}
                      titleColor={theme.colors.white}
                    />
                  </a>
                </Link>
              </div>
            );
          })}
        </HorisontalScrollContainer>
        {showRightArrow && generators && generators.length > 2 && (
          <GeneratorsRightArrowContainer>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                height: '100%',
                paddingBottom: 20,
              }}
            >
              <StyledArrowButton
                styles={{
                  background: theme.colors.white,
                  border: 'none',
                  height: 35,
                  width: 35,
                  borderRadius: '50%',
                  color: theme.colors.black,
                }}
                type="ghost"
                icon={<ArrowRightSvg />}
                notext={true}
                onClick={() => {
                  handleRightArrowClicked();
                }}
              />
            </div>
          </GeneratorsRightArrowContainer>
        )}
      </ScrollMainContainer>
    </DiscoveryRowContainer>
  );
};

const GeneratorsLeftArrowContainer = styled.div`
  position: absolute;
  height: calc(100% - 10px);
  width: 40px;
  left: 20px;
  top: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), ${theme.colors.green});
  z-index: 1;
`;

const HorisontalScrollContainer = styled.ul`
  width: 100%;
  height: 210px;
  overflow-x: auto;
  overflow-y: hidden;

  display: flex;
  gap: 10px;
  padding: 10px 0px 10px 0px; // padding bottom to contain scroll bar

  // Hide the horisontal scrollbars
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }

  .ant-skeleton,
  .ant-skeleton-button {
    height: 100%;
    aspect-ratio: 1.28;
    border-radius: 5px;
    min-width: unset;
    width: unset;
  }

  @media (max-width: 800px) {
    height: 150px;
  }
`;

const StyledArrowButton = styled(Button)`
  padding: 0px;
  svg {
    height: 28px;
    width: 28px;
  }
`;

const GeneratorsRightArrowContainer = styled.div`
  position: absolute;
  height: calc(100% - 10px);
  width: 40px;
  right: 20px;
  top: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.colors.green});
  z-index: 1;
`;

const ScrollMainContainer = styled.div`
  position: relative;
  width: 100%;
  height: 210px;
  padding: 0px 20px;
  @media (max-width: 800px) {
    height: 150px;
  }
`;

const DiscoveryRowContainer = styled.div`
  margin-bottom: 30px;

  @media (max-width: 800px) {
    margin-bottom: 30px;
  }
`;

const CollectionTitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    width: fit-content;
  }

  svg {
    margin-bottom: 5px;
  }

  svg:hover {
    cursor: pointer;
    color: ${theme.colors.iconGrey};
  }

  a {
    font-weight: bold;
    font-size: 16px;
    text-decoration: underline;
  }

  a:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

const Title = styled.h3<{ $fontSize: number }>`
  text-align: left;
  width: 100%;
  font-weight: bold;
  font-size: ${({ $fontSize }) => $fontSize}px;
  line-height: 140%;
  color: ${theme.colors.white};
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  text-transform: uppercase;
  padding-left: 30px;

  @media (max-width: 800px) {
    padding-left: 20px;
    margin-bottom: 0px;
  }

  @media (max-width: 690px) {
    font-size: ${({ $fontSize }) => $fontSize - 2}px;
  }
`;

const EmptyStateContainer = styled.div<{ $isGeneratorRow?: boolean }>`
  div > svg {
    height: 150px !important;
    width: 200px !important;
  }

  ${({ $isGeneratorRow }) =>
    $isGeneratorRow &&
    css`
      padding: 0px 20px;
      > div {
        margin-top: 0px;
        padding: 10px 0px;
        min-height: fit-content;
        div > svg {
          height: 70px !important;
          width: 100px !important;
        }
        p {
          margin: 0px;
        }
        h3 {
          margin-top: 5px !important;
        }
      }
    `}

  @media (max-width: 800px) {
    div > svg {
      height: 100px !important;
      width: 150px !important;
    }
  }
`;
